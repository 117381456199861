import Image from "../../image"

export const triFooterLinks = {
  social: {
    links: [
      { name: "facebook", link: "https://www.facebook.com/thereviewindex" },
      { name: "twitter", link: "https://twitter.com/thereviewindex" },
    ],
  },
  simple: {
    links: [
      { name: "About", link: "https://thereviewindex.com/about" },
      { name: "Contact", link: "https://thereviewindex.com/contact" },
      { name: "Pricing", link: "https://thereviewindex.com/pricing" },
      { name: "Blog", link: "https://thereviewindex.com/blog" },
      { name: "FAQs", link: "https://thereviewindex.com/faqs" },
      { name: "Privacy", link: "https://thereviewindex.com/privacy" },
      { name: "Terms", link: "https://thereviewindex.com/terms" },
    ],
  },
  primary: {
    1: {
      name: "Company",
      links: [
        { name: "About", link: "https://thereviewindex.com/about" },
        { name: "Contact", link: "https://thereviewindex.com/contact" },
        { name: "Blog", link: "https://thereviewindex.com/blog" },
        { name: "FAQs", link: "https://thereviewindex.com/faqs" },
        { name: "Privacy", link: "https://thereviewindex.com/privacy" },
        { name: "Terms", link: "https://thereviewindex.com/terms" },
      ],
    },
    2: {
      name: "Use Cases",
      links: [
        {
          name: "Amazon Review Analysis",
          link: "https://freetext.ai/blog/review-analysis-for-product-reviews",
        },
        { name: "Review Sentiment Analysis", link: "" },
        { name: "eCommerce Category Analysis", link: "" },
        { name: "Online Competition Analysis", link: "" },
      ],
    },
    3: {
      name: "Plugins",
      links: [
        {
          name: (
            <div className="flex gap-1">
              <Image
                className="w-5 h-5"
                src="/images/icons/chromeIcon.svg"
                alt="chrome-icon"
              />
              Get Chrome Plugin
            </div>
          ),
          link: "https://chrome.google.com/webstore/detail/thereviewindex-reviews-su/fciomokgnajoifiiaglcjkonligobneo",
        },
        {
          name: (
            <div className="flex gap-1">
              <Image
                className="w-5 h-5"
                src="/images/icons/firefoxIcon.svg"
                alt="firefox-icon"
              />
              Get Firefox Plugin
            </div>
          ),
          link: "https://addons.mozilla.org/en-US/firefox/addon/thereviewindex/",
        },
      ],
    },
    4: {
      name: "Free AI Tools",
      links: [
        { name: "AI Diagram Generator", link: "https://acme.bot/tools/ai-diagram-generator" },
        { name: "Free Blog Article Generator", link: "https://acme.bot/tools/ai-blog-generator" },
        { name: "Blog AI Agent", link: "https://acme.bot" },
        { name: "AEO Presence Report", link: "https://acme.bot/tools/aeo-presence-report" },
      ],
    },
  },
}
